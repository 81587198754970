import React from 'react';
import { useState } from 'react';
import classNames from 'classnames'
import Form, { Item as ItemForm, GroupItem, ColCountByScreen, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form';
import { EmailRule } from 'devextreme-react/validator';
import { FormTextBox } from '../FormTextBox/FormTextBox';

import './ContractForm.scss';

/** @typedef {import('../../types/contract').ContractPersonnel} ContractPersonnel */


export const ContractPersonnel = ({ data, editing, updateField }) => {
  const contractPersonnelLabel = ["CO", "COR", "ACOR"];
  // const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  let items = [];
  data.sort((a, b) => a['PersonnelTypeId'] - b['PersonnelTypeId'])
  const addItem = (newValue) => {
    // setItems((prevState) => [...prevState, newValue]);
    items = [...items, newValue]
  };

  const updateItem = (index, type, arr, field, newValue) => {
    //setItems(items[type][field] = newValue);
    //setItems((prevState) => prevState[type] = {...prevState[type], [field]: newValue});
    //setItems((prevState) => [...prevState, {[type]: {[field]: newValue}}]);
    //setItems((prevState) => [...prevState, item]);
    //items[index][field] = newValue;
    var i = items[index][field] = newValue;
    setItem(i);
    items = [...items, item]
    updateField(arr, index, field, newValue);

    if (data[index]['PersonnelTypeId'] === null || data[index]['PersonnelTypeId'] === undefined) 
      {
        var personnelTypeId = items[index]['PersonnelTypeId'] = index + 1;
        updateField(arr, index, 'PersonnelTypeId', personnelTypeId);
      }
  };

  if (items.length == 0) {
    contractPersonnelLabel.map((item, index) => {
      if (data[index] === null || data[index] === undefined)
        addItem({ OfficerFirstName: '', OfficerLastName: '', OfficerEmail: '', OfficerPhone: '', OfficerType: item });
      else
        addItem(
          {
            OfficerFirstName: data[index].OfficerFirstName,
            OfficerLastName: data[index].OfficerLastName,
            OfficerEmail: data[index].OfficerEmail,
            OfficerPhone: data[index].OfficerPhone,
            OfficerType: item
          });

    });
  }

  return (
    <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'>
      <GroupItem colCount={3}>
        {items.map((personnel, index) => {
          return (
            <GroupItem colCount={2} key={index} onValueChange>
              <ItemForm colSpan={2} key={index + 1}>
                <div><b>{personnel.OfficerType}</b></div>
              </ItemForm>
              <ItemForm key={index + 2}>
                <FormTextBox
                  label='First Name'
                  value={personnel.OfficerFirstName}
                  isEditing={editing}
                  onValueChange={e => updateItem(index, personnel.OfficerType, 'ContractPersonnel', 'OfficerFirstName', e)} />
              </ItemForm>
              <ItemForm key={index + 3}>
                <FormTextBox
                  label='Last Name'
                  value={personnel.OfficerLastName}
                  isEditing={editing}
                  onValueChange={e => updateItem(index, personnel.OfficerType, 'ContractPersonnel', 'OfficerLastName', e)} />
              </ItemForm>
              <ItemForm key={index + 4}>
                <FormTextBox
                  label='Email'
                  value={personnel.OfficerEmail}
                  isEditing={editing}
                  onValueChange={e => updateItem(index, personnel.OfficerType, 'ContractPersonnel', 'OfficerEmail', e)}>
                  <EmailRule />
                </FormTextBox>
              </ItemForm>
              <ItemForm key={index + 5}>
                <FormTextBox
                  label='Phone'
                  value={personnel.OfficerPhone}
                  mask='+1(000)000-0000'
                  isEditing={editing}
                  onValueChange={e => updateItem(index, personnel.OfficerType, 'ContractPersonnel', 'OfficerPhone', e)} />
              </ItemForm>
            </GroupItem>)
        })}
      </GroupItem>
    </Form>
  );
};