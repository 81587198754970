import {
    useEffect,
    useState,
    useCallback
} from "react";
import { ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY } from "../../routes/Routes";
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { BaseToggle } from "../../components/BaseToggle/BaseToggle";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { KeyboardNavigation } from 'devextreme-react/data-grid';
import FiscalYearSelectDropdown from '../FiscalYearSelectDropdown';
import IsNullOrEmpty from '../../components/IsNullOrEmpty';
import DataGridSpecificCostEstimateSummary from "./DataGridSpecificCostEstimateSummary";
import GetFetch from '../../hooks/GetFetch';
import '../SpecificCostEstimate/SpecificCostEstimate.scss';
import './SpecifiCostEstimateSummary.scss';

export default function SpecificCostEstimateSummary() {
    const {title} = ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY;
    const [selectedFiscalYear, setSelectedFiscalYear] = useState('');
    const [enableToggle, setEnableToggle] = useState(true)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [summaryCostsTabData, setSummaryCostsTabData] = useState({
        0: null, // Current Year == Fiscal Year
        1: null, // Budget Year == Fiscal Year +1 
        2: null, // Budget Year+1 == Fiscal Year +2
        3: null, // Budget Year+2 == Fiscal Year +3
        4: null  // Budget Year+3 == Fiscal Year +4
    });
    const [isTabDataReset, setIsDataReset] = useState(false);
    const [groupingToggle, setGroupingToggle] = useState(true);

    const fetchSummaryCostsByFiscalYear = useCallback(async (tabIndex) => {
        if (!selectedFiscalYear || summaryCostsTabData[tabIndex]) return;
        setLoading(true);
        const fiscalYear = selectedFiscalYear + tabIndex;
        const { Success, Message, Errors } = await GetFetch(`/v1/SummaryCostEstimate/${fiscalYear}/1,2,3,4,5,6,7,8,9,10,11`);  //passing in all regions;
        if (Success) {
            setEnableToggle(false);
            setSummaryCostsTabData(prevData => ({
                ...prevData,
                [tabIndex]: Message
            }));
        }
        else {
            setError(true);
            setEnableToggle(true);
            if (Errors[0].Message === "No records found at the moment") {
                console.log(Errors[0].Message);
            }
        }
        setLoading(false);
    }, [selectedFiscalYear, summaryCostsTabData]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSelectedTabIndex(0);
            setSummaryCostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
            setIsDataReset(true);
        }
    }

    const handleSubmit = async () => {
        setSelectedTabIndex(0);
        setSummaryCostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
        setIsDataReset(true);
    }

    useEffect(()=> {
        if (isTabDataReset) {
            fetchSummaryCostsByFiscalYear(0);
            setIsDataReset(false);
        }
    }, [isTabDataReset]);

    const onTabSelectionChanged = useCallback((e) => {
        const newTabIndex = e.component.option('selectedIndex');
        setSelectedTabIndex(newTabIndex);
        fetchSummaryCostsByFiscalYear(newTabIndex);
    }, [fetchSummaryCostsByFiscalYear]);

    const hint = groupingToggle ?
        "Click to enable grouping" : "Click to disable grouping";

    const switchElemAttr = {
        id: 'switch-element-attribute'
    }

    return (
        <div>
            <h1>{ title }</h1>
            <em>
                Please select a current year
            </em>
            <div className="inline__div--wrapper">
                <FiscalYearSelectDropdown
                    inputAttribute={{ "aria-label": "Current Year *" }}
                    enableSearch={true}
                    label={"Current Year"}
                    onEnterKeyDown={handleKeyDown}
                    value={selectedFiscalYear}
                    optionUpdatedByUser={({ selectedItem }) => setSelectedFiscalYear(selectedItem)}
                    isVisible={true}
                />
                <BaseButton 
                    ariaDisabled={IsNullOrEmpty(selectedFiscalYear)}
                    disabled={IsNullOrEmpty(selectedFiscalYear)}
                    label={"Search"}
                    onClick={handleSubmit}
                    variant={"outlined"}
                />
                <BaseToggle
                    disabled={enableToggle}
                    elementAttribute={switchElemAttr}
                    hint={hint}
                    name={"toggle grouping"}
                    valueUpdatedByUser={({value}) => setGroupingToggle(value)}
                    rightToLeftEnabled={true}
                    switchedOnText={"Enable Grouping"}
                    switchedOffText={"Disable Grouping"}
                    width={120}
                    value={groupingToggle} 
                />
            </div>
            <div className='inline-div--tab-panel-wrapper'>
                <TabPanel id="tabPanel" onSelectionChanged={onTabSelectionChanged} selectedIndex={selectedTabIndex}>
                    <KeyboardNavigation enabled={true} />
                    <TabPanelItem title={`Current Year (${selectedFiscalYear})`} id="cy-cost-est" aria-label="cy-cost-est">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[0]} fiscalYear={selectedFiscalYear} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year (${selectedFiscalYear + 1})`} id="budget-year-cost-est" aria-label="budget-year-cost-est">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[1]} fiscalYear={selectedFiscalYear + 1} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+1 (${selectedFiscalYear + 2})`} id="budget-year-cost-est+1" aria-label="budget-year-cost-est+1">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[2]} fiscalYear={selectedFiscalYear + 2} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+3 (${selectedFiscalYear + 3})`} id="budget-year-cost-est+2" aria-label="budget-year-cost-est+2">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[3]} fiscalYear={selectedFiscalYear + 3} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+4 (${selectedFiscalYear + 4})`} id="budget-year-cost-est+4" aria-label="budget-year-cost-est+4">
                        <DataGridSpecificCostEstimateSummary summaryCosts={summaryCostsTabData[4]} fiscalYear={selectedFiscalYear + 4} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                </TabPanel>
            </div>
        </div>
    );
}