import React, { useRef, useState } from "react";
import DataGrid, {
    Column,
    KeyboardNavigation,
    FilterRow,
    Paging,
    Pager,
    SearchPanel,
    Toolbar,
    Item,
} from 'devextreme-react/data-grid';
import { useNavigate } from "react-router-dom";
import { ROUTE_CREATE_EDIT_POST } from "../../routes/Routes";
import { allowedPageSizes } from "../../components/GlobalDataGridConfigurations";

export default function DataGridPost({ data, editing }) {
    const navigate = useNavigate();

    const navigateToNewPostForm = (() => {
        navigate(ROUTE_CREATE_EDIT_POST.withSlash, {
            state: {
                postExhibit: data,
                isNewPostExhibit: true
            }
        });
    })

    const navigateToEditPostForm = (postId) => {
        navigate(ROUTE_CREATE_EDIT_POST.withSlash, {
            state: {
                post: data.Posts.find(p => p.PostId === postId),
                postExhibit: data
            }
        });
    }

    return (
        <DataGrid
            id="postExhibit_postDataGrid"
            columnAutoWidth={true}
            dataSource={data?.Posts}
            showBorders={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            key="PostId"
        >
            <FilterRow visible={true} />
            <KeyboardNavigation enabled={true} />
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
            />
            <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
            />
            <Toolbar>
                <Item
                    widget="dxButton"
                    location="after"
                    options={{
                        icon: 'add',
                        text: 'New Post',
                        onClick: () => {
                            navigateToNewPostForm();
                        }
                    }}
                    visible={editing}
                />
            </Toolbar>
            {editing ?
                (
                    <Column
                        dataField="PostId"
                        caption="Post ID"
                        cellRender={(cellData) => (
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateToEditPostForm(cellData.value);
                                }}
                            >
                                Edit: {cellData.value}
                            </a>
                        )}
                    />
                ) :
                <Column
                    dataField="PostId"
                    caption="Post ID"
                />
            }
            <Column
                dataField="TaskOrderId"
                caption="TaskOrder ID"
                allowHeaderFiltering={true}
            />
            <Column
                dataField="CLIN"
                caption="CLIN"
            />
            <Column
                dataField="BuildingId"
                caption="Building Number"
            />
            <Column
                dataField="Building.Location.Address1"
                caption="Address"
            />
            <Column
                dataField="Building.Location.CityName"
                caption="City"
            />
            <Column
                dataField="Building.Location.State"
                caption="State"
            />
            <Column
                dataField="PostLocation.Name"
                caption="Post Location"
            />
            <Column
                dataField="StartDate"
                caption="Start Date"
            />
            <Column
                dataField="EndDate"
                caption="End Date"
            />
        </DataGrid>
    )
}