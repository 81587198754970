import { DropDownBox, List, TextBox } from "devextreme-react";
import { useRef, useState } from "react";
import Box, { Item } from "devextreme-react/box";
import {Validator, RequiredRule as ValidatorReqRule } from "devextreme-react/validator";
const ItemProductDropdowns = ({ value, setter, index, productData }) => {
    const [descriptionData, setDescriptionData] = useState([]);
    const dropDownBoxRef = useRef(null);
    return (
        <Box>
            <Item ratio={3}>
                <DropDownBox
                    label="Item Product Code *"
                    labelMode="static"
                    height={56}
                    width={188}
                    value={value.ProductCodeId}
                    valueExpr="ProductCodeId"
                    displayExpr="Code"
                    dataSource={productData}
                    ref={dropDownBoxRef}
                    isValid = {value.ProductCodeId}
                    onValueChanged={(e) => {
                        // console.log('dropdown func', e);
                        let objectCopy = JSON.parse(JSON.stringify(value));
                        objectCopy.ProductCodeId = e.value;
                        setter(objectCopy);
                    }}
                >
                    <Validator>
                        <ValidatorReqRule type="required" />
                    </Validator>
                    <List
                        selectionMode="single"
                        ref={dropDownBoxRef}
                        displayExpr="Code"
                        onSelectionChanged={(e) => {
                            console.log('list func', e);
                            let objectCopy = JSON.parse(JSON.stringify(value));
                            objectCopy.ProductCodeId = e.addedItems[0].ProductCodeId;
                            setDescriptionData(e.addedItems[0].ProductDescriptions);
                            // console.log("product drescriptions:", e.addedItems[0].ProductDescriptions)
                            setter(objectCopy);
                            dropDownBoxRef.current.instance.close();
                        }}
                        dataSource={productData}
                    />
                </DropDownBox>
            </Item>
            <Item ratio={1}>
            
                    <DropDownBox
                        label="Item Product Description *"
                        labelMode="static"
                        height={56}
                        width={250}
                        disabled={descriptionData.length === 0}
                        value={value.ProductDescriptionId}
                        valueExpr="ProductDescriptionId"
                        displayExpr="Description"
                        dataSource={descriptionData}
                        ref={dropDownBoxRef}
                        isValid = {value.ProductDescriptionId}
                        onValueChanged={(e) => {
                            // console.log('dropdown func', e);
                            let objectCopy = JSON.parse(JSON.stringify(value));
                            objectCopy.ProductDescriptionId = e.value;
                            setter(objectCopy);
                        }}
                    >
                        <List
                            selectionMode="single"
                            ref={dropDownBoxRef}
                            displayExpr={"Description"}
                            onSelectionChanged={(e) => {
                                // console.log('list func', e);
                                let objectCopy = JSON.parse(JSON.stringify(value));
                                objectCopy.ProductDescriptionId = e.addedItems[0].ProductDescriptionId;
                                setter(objectCopy);
                                dropDownBoxRef.current.instance.close();
                            }}
                            dataSource={descriptionData}
                        />
                    </DropDownBox>
               
            </Item>
        </Box>
    )
}
const ItemProductDescriptionDropdown = ({ value, setter, index }) => {
    const data = ["Housekeeping - Guard", "Main Post - Guard"];
    const dropDownBoxRef = useRef(null);
    return (
        <>
            <DropDownBox
                label="Item Product Description"
                labelMode="floating"
                height={56}
                width={250}
                value={value.ProductDescriptionId}
                // valueExpr="key" 
                // displayExpr="value"
                dataSource={data}
                ref={dropDownBoxRef}
                onValueChanged={(e) => {
                    // console.log('dropdown func', e);
                    let objectCopy = JSON.parse(JSON.stringify(value));
                    objectCopy.ProductDescriptionId = e.value;
                    setter(objectCopy);
                }}
            >
                <List
                    selectionMode="single"
                    ref={dropDownBoxRef}
                    // displayExpr={"value"}
                    onSelectionChanged={(e) => {
                        // console.log('list func', e);
                        let objectCopy = JSON.parse(JSON.stringify(value));
                        objectCopy.ProductDescriptionId = e.addedItems[0];
                        setter(objectCopy);
                        dropDownBoxRef.current.instance.close();
                    }}
                    dataSource={data}
                />
            </DropDownBox>
        </>
    )
}
export default ItemProductDropdowns;