import React, { useState, useEffect, useRef, useCallback } from "react";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { BaseNumberBox } from "../../components/BaseNumberBox/BaseNumberBox";
import { FormTextBox } from '../../components/FormTextBox/FormTextBox';
import { ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_POST_EXHIBITS_LIST_PAGE } from "../../routes/Routes";
import "../css/PostExhibits.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ColCountByScreen, Form, Item as FormItem, GroupItem, TabbedItem, TabPanelOptions, Tab, Item } from "devextreme-react/form";
import GetFetch from "../../hooks/GetFetch";
import { SelectBox, ValidationGroup } from "devextreme-react";
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import DataGridPost from "./DataGridPost";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { FormDateBox } from "../../components/FormDateBox/FormDateBox";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import notify from "devextreme/ui/notify";

const URL_contracts = '/v1/Contract/GetContracts';
const URL_clonePostExhibit = '/v1/PostExhibit/ClonePostExhibit/'; //+ PostExhibitId
const URL_updatePostExhibit = '/v1/PostExhibit/UpdatePostExhibit';
const URL_addPostExhibit = '/v1/PostExhibit/AddPostExhibit';

/** @type {import("../../types/postExhibit").PostExhibit} */
const defaultPostExhibit = {
    PostExhibitId: null,
    TaskOrderId: null,
    ClonedPostExhibitId: null,
    TaskOrderNumber: null,
    PostExhibitStatusId: null,
    Name: null,
    FiscalYear: null,
    StartDate: null,
    EndDate: null,
    TaskOrder: {
        TaskOrderId: null,
        Number: null,
        StartDate: null,
        EndDate: null,
        AwardDate: null,
        VendorName: null,
        LineNumber: null,
        ContractId: null,
        ProductCodeId: null,
        ProductDescriptionId: null,
        RegionId: null,
        TaskOrderTypeId: null,
        ModifyTaskOrderId: null,
        ItemNumber: 0,
        FinancialCodes: null,
        PostExhibit: null
    },
    PostExhibitStatus: null,
    CostEstimates: null,
    Posts: null
}

export default function PostExhibitForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [taskOrders, setTaskOrders] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [selectedTaskOrderNumber, setSelectedTaskOrderNumber] = useState(null);
    const [editing, setEditing] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const [posts, setPosts] = useState(null);
    const dataRef = useRef(null);
    const [postExhibitFormData, setPostExhibitFormData] = useState(defaultPostExhibit);

    useEffect(() => {
        const state = location.state;
        if (state) {
            setPostExhibitFormData(state?.postExhibit);
            setPosts(state?.postExhibit?.Posts);
            setSelectedContractId(state?.postExhibit?.TaskOrder?.ContractId);
            setSelectedTaskOrderNumber(state?.postExhibit?.TaskOrderNumber);
            if (state?.isEditing) {
                setEditing(state.isEditing);
            }
            if (state?.isNewPostExhibit) {
                setIsNewPostExhibit(state.isNewPostExhibit);
                fetchAndSet(URL_contracts, setContracts);
            }
        } else {
            //New PostExhibit
            setEditing(true);
            setIsNewPostExhibit(true);
            fetchAndSet(URL_contracts, setContracts);
        }
    }, [location.state]);

    const fetchAndSet = async (url, setter) => {
        const { Errors, Message, Success } = await GetFetch(url);
        if (Success === true) {
            setter(Message);
        } else {
            console.error(Errors[0].Message);
        }
    }

    const updateField = useCallback((field, value) => {
        setPostExhibitFormData((prevData) => {
            // Handle nested fields like 'Building.Location.Address1'
            if (field.includes('.')) {
                const [parent, ...rest] = field.split('.');
                const nestedField = rest.join('.');

                return {
                    ...prevData,
                    [parent]: {
                        ...prevData[parent],
                        ...(nestedField.includes('.')
                            ? {
                                [nestedField.split('.')[0]]: {
                                    ...prevData[parent][nestedField.split('.')[0]],
                                    [nestedField.split('.')[1]]: value
                                }
                            }
                            : { [nestedField]: value }
                        )
                    }
                };
            }

            // Handle top-level fields
            return { ...prevData, [field]: value };
        });
    }, []);

    const handleContractSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.ContractId !== selectedContractId) {
            setSelectedContractId(selectedItem.ContractId);
            const contract = contracts.find(contract => contract.ContractId === selectedItem.ContractId);
            if (contract && contract.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            } else {
                setTaskOrders([]);
            }

            updateField('TaskOrder.ContractId', selectedItem.ContractId);
        }
    }, [contracts, selectedContractId, updateField]);

    const handleTaskOrderSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.Number !== selectedTaskOrderNumber) {
            setSelectedTaskOrderNumber(selectedItem.Number);
            updateField('TaskOrderNumber', selectedItem.Number);
            updateField('TaskOrderId', selectedItem.TaskOrderId);
            updateField('TaskOrder', selectedItem);
        }
    }, [selectedTaskOrderNumber, updateField]);

    const onClonePostExhibit = async () => {
        try {
            const { Errors, Message, Success } = await PutPostPatchFetch(
                `${URL_clonePostExhibit}${postExhibitFormData.PostExhibitId}`,
                'POST',
                null
            );

            if (Success) {
                const clonedPostExhibitId = Message.PostExhibitId;
                showMessage(`Success! Cloned Post Exhibit ID: ${clonedPostExhibitId}`, true);
            } else {
                showMessage('Error cloning post exhibit', false);
            }
        } catch (error) {
            console.error('Error cloning post exhibit:', error);
            showMessage('An unexpected error occurred while cloning', false);
        }
    }

    const showMessage = (message, success, displayTime = 7500, width = 500) => {
        notify(
            {
                message: message,
                width: width,
                position: {
                    at: 'top',
                    my: 'top',
                    of: '#container'
                }
            },
            success ? "success" : 'error',
            displayTime
        )
    }

    const replaceNullIds = (obj) => {
        const replacedObj = { ...obj };

        // Replace null IDs in the main object
        Object.keys(replacedObj).forEach(key => {
            if (key.toLowerCase().endsWith('id') && replacedObj[key] === null) {
                replacedObj[key] = 0;
            }
        });

        // Handle nested objects (like TaskOrder)
        Object.keys(replacedObj).forEach(key => {
            if (typeof replacedObj[key] === 'object' && replacedObj[key] !== null) {
                replacedObj[key] = replaceNullIds(replacedObj[key]);
            }
        });

        // Handle Posts array if it exists
        if (Array.isArray(replacedObj.Posts)) {
            replacedObj.Posts = replacedObj.Posts.map(post => {
                const replacedPost = { ...post };

                // Replace null PostId with zero for new posts
                if (IsNullOrEmpty(replacedPost.PostId)) {
                    replacedPost.PostId = 0;
                }

                // Replace null IDs in the post object
                Object.keys(replacedPost).forEach(key => {
                    if (key.toLowerCase().endsWith('id') && replacedPost[key] === null) {
                        replacedPost[key] = 0;
                    }
                });

                return replacedPost;
            });
        }

        return replacedObj;
    }

    const onSaveClick = async ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) return;

        const preparedPostExhibitData = replaceNullIds(postExhibitFormData);

        setEditing(false);

        try {
            const isNewPostExhibit = IsNullOrEmpty(preparedPostExhibitData.PostExhibitId) || preparedPostExhibitData.PostExhibitId === 0;
            const saveUrl = isNewPostExhibit ? URL_addPostExhibit : URL_updatePostExhibit;
            const { Success, Errors, Message } = await PutPostPatchFetch(
                saveUrl,
                'POST',
                preparedPostExhibitData
            );

            if (Success) {
                showMessage(
                    isNewPostExhibit ? 'New Post Exhibit created successfully' : 'Post Exhibit updated successfully',
                    Success,
                    3000
                );

                navigate(ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash);
            } else {
                showMessage(Errors?.join(', ') || 'Failed to save Post Exhibit', false, 5000);
                setEditing(true);
            }
        } catch (error) {
            console.error('Error saving Post Exhibit:', error);
            showMessage('An unexpected error occurred while saving', false, 5000);
            setEditing(true);
            setError(true);
        }
    }

    const handleEditClick = () => {
        if (editing === false && postExhibitFormData) {
            dataRef.current = postExhibitFormData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    }

    const onCancelClick = () => {

        const isFormChanged = (original, current) => {
            return JSON.stringify(original) === JSON.stringify({
                ...current,
                // Optionally exclude certain fields from comparison if needed
            });
        }
        if (!isFormChanged(defaultPostExhibit, postExhibitFormData)) {
            setPostExhibitFormData(dataRef.current);
        }
        handleEditClick();
    }

    const getTaskOrderTypeString = () => {
        const taskOrderTypeId = postExhibitFormData?.TaskOrder?.TaskOrderTypeId || 0;
        switch (taskOrderTypeId) {
            case 1:
                return "Base";
            case 2:
                return "Option";
            case 3:
                return "Modification";
            default:
                return "";
        }
    }

    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split('T')[0];
    }

    const formatTime = (time) => {
        if (!time) return null;
        const formattedTime = new Date(time);

        // Convert to hours as a decimal (double precision)
        const hours = formattedTime.getHours();
        const minutes = formattedTime.getMinutes();
        const decimalTime = hours + (minutes / 60);

        return parseFloat(decimalTime.toFixed(4));
    }

    return (
        <div id="container">
            <h1>{"Post Exhibit Details"}</h1>
            {!isNewPostExhibit &&
                <BaseButton
                    label={"Clone Post Exhibit"}
                    onClick={onClonePostExhibit}
                    variant={"contained"}
                    disabled={editing}
                />
            }
            <div className="inline_div--form-wrapper">
                <ValidationGroup>
                    <ToolbarForm toggleEditing={handleEditClick} onSaveClick={onSaveClick} editing={editing} onCancelClick={onCancelClick} />
                    <Form>
                        <GroupItem
                            caption={isNewPostExhibit ? '  '
                                : "Post Exhibit ID: " + (
                                    !IsNullOrEmpty(postExhibitFormData?.PostExhibitId) ? postExhibitFormData.PostExhibitId : ''
                                )} >
                            <TabbedItem >
                                <TabPanelOptions deferRendering={false} />
                                <Tab title="Contract Summary" >
                                    {/* Contract Number, contract Personnel*/}
                                    {isNewPostExhibit ?
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <SelectBox
                                                        inputAttr={{ 'aria-label': 'ContractId' }}
                                                        label='Contract Id'
                                                        dataSource={contracts}
                                                        displayExpr={'Number'}
                                                        valueExpr={'ContractId'}
                                                        value={selectedContractId}
                                                        onSelectionChanged={(e) => handleContractSelection(e.selectedItem)}
                                                        readOnly={!editing}
                                                        searchEnabled={true}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        ) :
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Contract Id'
                                                        value={postExhibitFormData?.TaskOrder?.ContractId}
                                                        isEditing={editing}
                                                        onValueChange={e => updateField('TaskOrder.ContractId', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='CO'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='COR'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='ACOR'
                                                        value={''}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('', e)}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        )
                                    }
                                </Tab>
                                <Tab title="Task Order Summary" >
                                    {/* T.O Number, start date, end date, vendor, region, type*/}
                                    {isNewPostExhibit ? (
                                        <GroupItem colCount={4}>
                                            <FormItem>
                                                <SelectBox
                                                    inputAttr={{ 'aria-label': 'Task Order Number' }}
                                                    label='Task Order Number'
                                                    dataSource={taskOrders}
                                                    displayExpr={'Number'}
                                                    valueExpr={'Number'}
                                                    value={selectedTaskOrderNumber}
                                                    onSelectionChanged={(e) => handleTaskOrderSelection(e.selectedItem)}
                                                    readOnly={!editing}
                                                    searchEnabled={true}
                                                    disabled={!taskOrders.length}
                                                />
                                            </FormItem>
                                        </GroupItem>
                                    ) :
                                        (
                                            <GroupItem colCount={4}>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Task Order Number'
                                                        value={postExhibitFormData?.TaskOrderNumber}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrderNumber', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormDateBox
                                                        label='Start Date'
                                                        value={postExhibitFormData?.TaskOrder?.StartDate}
                                                        isEditing={false}
                                                        onValueChange={e => {
                                                            const formattedDate = formatDate(e)
                                                            updateField('TaskOrder.StartDate', formattedDate)
                                                        }}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormDateBox
                                                        label='End Date'
                                                        value={postExhibitFormData?.TaskOrder?.EndDate}
                                                        isEditing={false}
                                                        onValueChange={e => {
                                                            const formattedDate = formatDate(e)
                                                            updateField('TaskOrder.EndDate', formattedDate)
                                                        }}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Vendor'
                                                        value={postExhibitFormData?.TaskOrder?.VendorName}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.VendorName', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Region'
                                                        value={postExhibitFormData?.TaskOrder?.RegionId}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.RegionId', e)}
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <FormTextBox
                                                        label='Type'
                                                        value={getTaskOrderTypeString()}
                                                        isEditing={false}
                                                        onValueChange={e => updateField('TaskOrder.TaskOrderTypeId', e)}
                                                    />
                                                </FormItem>
                                            </GroupItem>
                                        )
                                    }
                                </Tab>
                                <Tab title="Post Exhibit" >
                                    <GroupItem colCount={4}>
                                        <FormItem>
                                            <FormTextBox
                                                label='Name'
                                                value={postExhibitFormData?.Name}
                                                isEditing={editing}
                                                onValueChange={e => updateField('Name', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormTextBox
                                                label='Fiscal Year'
                                                value={postExhibitFormData?.FiscalYear}
                                                isEditing={editing}
                                                onValueChange={e => updateField('FiscalYear', e)}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <SelectBox
                                                inputAttr={{ 'aria-label': 'Status' }}
                                                label='Status'
                                                dataSource={getPostExhibitStatuses()}
                                                displayExpr={'text'}
                                                valueExpr={'id'}
                                                value={postExhibitFormData?.PostExhibitStatusId}
                                                onSelectionChanged={(e) => updateField('PostExhibitStatusId', e.selectedItem.id)}
                                                readOnly={!editing}
                                                searchEnabled={true}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormDateBox
                                                label='Start Date'
                                                value={postExhibitFormData?.StartDate}
                                                isEditing={editing}
                                                onValueChange={e => {
                                                    const formattedDate = formatDate(e)
                                                    updateField('StartDate', formattedDate)
                                                }}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormDateBox
                                                label='End Date'
                                                value={postExhibitFormData?.EndDate}
                                                isEditing={editing}
                                                onValueChange={e => {
                                                    const formattedDate = formatDate(e)
                                                    updateField('EndDate', formattedDate)
                                                }}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <FormTextBox
                                                label='Cloned PostExhibit ID'
                                                value={postExhibitFormData?.ClonedPostExhibitId}
                                                isEditing={editing}
                                                onValueChange={e => updateField('ClonedPostExhibitId', e)}
                                            />
                                        </FormItem>
                                    </GroupItem>
                                </Tab>
                            </TabbedItem>
                        </GroupItem>
                        <GroupItem caption="Posts">
                            <DataGridPost data={postExhibitFormData} editing={editing} />
                        </GroupItem>
                    </Form>
                </ValidationGroup>
            </div>
        </div>

    )
}

// ================== HELPER FUNCTIONS ============================ //

function getPostExhibitStatuses() {
    return [
        { id: 1, text: 'Active' },
        { id: 2, text: 'Draft' },
        { id: 3, text: 'Expired' },
        { id: 6, text: 'Actual' }
    ]
}