import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Form, Item as FormItem, GroupItem } from "devextreme-react/form";
import RegionDropdown from "../../Users/AddUserComponents/RegionDropdown";
import SingleRegionSelectFieldDevEx from "../../CustomerAccounts/components/RegionSingleSelectDropDownDevEx";
import SingleRegionSelectField from "../../BuildingsPage/components/SingleRegionSelectField/SingleRegionSelectField";
import BldgNumberDropDownDevEx from '../../Reports/CostEstimateReport/BldgNumberDropdownDevEx';
import { useLocation, useNavigate } from "react-router-dom";
import { DateBox, NumberBox, SelectBox, TagBox, TextBox, ValidationGroup } from "devextreme-react";
import GetFetch from "../../hooks/GetFetch";
import { ROUTE_CREATE_EDIT_POST_EXHIBIT } from "../../routes/Routes";
import { BaseNumberBox } from "../../components/BaseNumberBox/BaseNumberBox";
import { BaseCheckBox } from "../../components/BaseCheckBox/BaseCheckBox";
import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";
import { BaseDateField } from "../../components/BaseDateField/BaseDateField";
import { BaseTimeField } from "../../components/BaseTimeField/BaseTimeField";
import { BaseMultiSelect } from "../../components/BaseMultiSelect/BaseMultiSelect";
import { FormTextBox } from "../../components/FormTextBox/FormTextBox";
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import dayjs from "dayjs";

const URL_regions = '/v1/Region';
const URL_buildings = '/v1/Building/Region/'; //+ Region
const URL_buildingBySequenceNumber = '/v1/Building/ById/'; //+ buildingSeqNumber
const URL_postStatuses = '/v1/PostStatus/GetPostStatus';
const URL_feeTypes = '/v1/FeeType/GetFeeTypes';
const URL_postTypes = '/v1/PostType';
const URL_postLocations = '/v1/PostLocation';
const URL_screeners = '/v1/Screener';
const URL_securityLevels = '/v1/SecurityLevel';

/** @type {import('../../../src/types/postExhibit').Post} */
const defaultPostFormData = {
    PostId: null,
    CLIN: null,
    TaskOrderId: null,
    PostLocationId: null,
    PostLocation: null,
    FeeTypeId: null,
    BuildingId: null,
    Building: {
        BuildingId: null,
        BuildingName: '',
        RegionNumber: null,
        SequenceNumber: null,
        Location: {
            Id: null,
            Address1: '',
            Address2: '',
            CityName: '',
            State: '',
            ZipCode: ''
        }
    },
    PostTypeId: null,
    PostStatusId: null,
    PrimaryPostTypeId: null,
    SecondaryPostTypeId: null,
    ScreenerId: null,
    SecurityLevelId: null,
    SpecialRequirements: '',
    HourlyRate: null,
    StartDate: null,
    EndDate: null,
    StartTime: null,
    EndTime: null,
    HolidayStartTime: null,
    HolidayEndTime: null,
    ReliefBreak: null,
    Utilization: null,
    WeeklySchedule: '',  // comma-separated string of selected days
    HolidaySchedule: '', // comma-separated string of selected holidays    
    AdditionalServiceHours: null,
    TotalServiceHours: null,
    TotalServiceCosts: null,
    FeeType: null,
    PostStatus: null,
    PrimaryPostType: null,
    SecondaryPostType: null,
    Screener: null,
    SecurityLevel: null
}

export default function PostForm() {
    const [isLoading, setIsLoading] = useState(true);
    const [postFormData, setPostFormData] = useState(defaultPostFormData);
    const [postExhibitFormData, setPostExhibitFormData] = useState(null);
    const [calculatedFields, setCalculatedFields] = useState({
        totalHoursPerDay: 0,
        totalNonHolidays: 0,
        totalHolidayDays: 0,
        totalCalendarDaysWorked: 0,
        totalHours: 0,
        totalHolidayHours: 0
    });

    // State for dropdowns
    const [_buildingNumber, set_BuildingNumber] = useState(null);
    const [regions, setRegions] = useState([]);
    const [buildingsData, setBuildingsData] = useState([]);
    const [selectedRegionNumber, setSelectedRegionNumber] = useState(null);
    const [selectedBuildingId, setSelectedBuildingId] = useState(null);
    const [buildingDetails, setBuildingDetails] = useState({
        Name: "",
        Address1: "",
        CityName: "",
        State: "",
        ZipCode: ""
    });

    const [dropdownData, setDropdownData] = useState({
        postStatuses: [],
        feeTypes: [],
        postTypes: [],
        screeners: [],
        securityLevels: [],
        regions: [],
        buildings: []
    });

    // UI state
    const [editing, setEditing] = useState(false);
    const [isNewPost, setIsNewPost] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const dataRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const now = new Date();
    const minDate = new Date(1900, 0, 1);

    const loadDropdowns = async () => {
        const fetchData = async (url) => {
            const { Message, Success } = await GetFetch(url);
            return Success ? Message : [];
        };
        const results = await Promise.allSettled([
            fetchData(URL_postStatuses),
            fetchData(URL_feeTypes),
            fetchData(URL_postTypes),
            fetchData(URL_screeners),
            fetchData(URL_securityLevels),
            fetchData(URL_regions),
        ]);

        setDropdownData({
            postStatuses: results[0].status === 'fulfilled' ? results[0].value : [],
            feeTypes: results[1].status === 'fulfilled' ? results[1].value : [],
            postTypes: results[2].status === 'fulfilled' ? results[2].value : [],
            screeners: results[3].status === 'fulfilled' ? results[3].value : [],
            securityLevels: results[4].status === 'fulfilled' ? results[4].value : [],
            regions: results[5].status === 'fulfilled' ? results[5].value : [],

        });
    };

    useEffect(() => {
        loadDropdowns();
    }, []);

    useEffect(() => {
        const post = location.state?.post;
        if (post) {
            setIsNewPost(false);
            setPostFormData({
                ...post,
                WeeklySchedule: post.WeeklySchedule ?? '',
                HolidaySchedule: post.HolidaySchedule ?? '',
                StartTime: formatTimeForUI(post.StartTime),
                EndTime: formatTimeForUI(post.EndTime),
                HolidayStartTime: formatTimeForUI(post.HolidayStartTime),
                HolidayEndTime: formatTimeForUI(post.HolidayEndTime)
            });
            if (post.Building) {
                setSelectedRegionNumber(post.Building?.RegionNumber);
                setSelectedBuildingId(post.Building?.BuildingId);
                setBuildingDetails({
                    Name: post.Building?.Name,
                    Address1: post.Building?.Location?.Address1,
                    CityName: post.Building?.Location?.CityName,
                    State: post.Building?.Location?.State,
                    ZipCode: post.Building?.Location?.ZipCode
                });
            }
        } else {
            setEditing(true);
            setIsNewPost(true);
            setPostFormData(defaultPostFormData);
        }

        setIsNewPostExhibit(location.state?.isNewPostExhibit);
        setPostExhibitFormData(location.state?.postExhibit);
    }, [location.state]);

    // Calculate total hours per day
    useEffect(() => {
        if (postFormData.StartTime && postFormData.EndTime) {
            const start = dayjs(postFormData.StartTime);
            const end = dayjs(postFormData.EndTime);
            if (start.isValid() && end.isValid()) {
                const difference = end.diff(start, 'hour', true);
                setCalculatedFields(prev => ({
                    ...prev,
                    totalHoursPerDay: difference >= 0 ? Number(difference.toFixed(2)) : 0
                }));
            } else {
                console.error("Invalid start or end time format");
            }
        }
    }, [postFormData.StartTime, postFormData.EndTime]);

    // Calculate holiday and non-holiday days
    useEffect(() => {
        const { StartDate, EndDate, WeeklySchedule, HolidaySchedule } = postFormData;

        if ((StartDate && EndDate) && (WeeklySchedule.length > 0 || HolidaySchedule.length > 0)) {
            const scheduleArray = typeof WeeklySchedule === 'string' ? WeeklySchedule.split(',') : WeeklySchedule;
            const holidayArray = typeof HolidaySchedule === 'string' ?
                HolidaySchedule.split(',') : HolidaySchedule;

            const totalHolidayDays = calculateTotalHolidayDays(StartDate, EndDate, holidayArray);
            const totalNonHolidays = calculateTotalNonHolidayDays(StartDate, EndDate, scheduleArray, holidayArray);

            setCalculatedFields(prev => ({
                ...prev,
                totalHolidayDays,
                totalNonHolidays,
                totalCalendarDaysWorked: totalNonHolidays + totalHolidayDays
            }));
        }
    }, [postFormData.StartDate, postFormData.EndDate, postFormData.WeeklySchedule, postFormData.HolidaySchedule]);

    // Calculate total hours
    useEffect(() => {
        const { totalHoursPerDay, totalNonHolidays, totalHolidayDays } = calculatedFields;
        const holidayHours = calculateTotalHolidayHours(
            postFormData.HolidayStartTime,
            postFormData.HolidayEndTime,
            totalHolidayDays
        );

        const total = (totalHoursPerDay * totalNonHolidays) +
            holidayHours +
            postFormData.AdditionalServiceHours;

        setCalculatedFields(prev => ({
            ...prev,
            totalHours: total,
            totalHolidayHours: holidayHours
        }));
    }, [
        calculatedFields.totalHoursPerDay,
        calculatedFields.totalNonHolidays,
        calculatedFields.totalHolidayDays,
        postFormData.HolidayStartTime,
        postFormData.HolidayEndTime,
        postFormData.AdditionalServiceHours
    ]);

    const updateField = useCallback((field, value) => {
        setPostFormData((prevData) => {
            // Handle nested fields like 'Building.Location.Address1'
            if (field.includes('.')) {
                const [parent, ...rest] = field.split('.');
                const nestedField = rest.join('.');

                return {
                    ...prevData,
                    [parent]: {
                        ...prevData[parent],
                        ...(nestedField.includes('.')
                            ? {
                                [nestedField.split('.')[0]]: {
                                    ...prevData[parent][nestedField.split('.')[0]],
                                    [nestedField.split('.')[1]]: value
                                }
                            }
                            : { [nestedField]: value }
                        )
                    }
                };
            }

            // Handle top-level fields
            return { ...prevData, [field]: value };
        });
    }, []);

    const memoizedDropdownData = useMemo(() => dropdownData, [dropdownData]);

    const fetchAndSetBuildingsForRegion = async (regionNumber) => {
        const { Message, Success } = await GetFetch(`${URL_buildings}${regionNumber}`);
        if (Success) {
            setBuildingsData(Message);
        } else {
            console.error("Error fetching buildings for the selected region.");
        }
    }

    const handleRegionSelection = useCallback((selectedItem) => {
        const regionNumber = selectedItem.RegionNumber;
        if (selectedItem && regionNumber !== selectedRegionNumber) {
            setSelectedRegionNumber(regionNumber);
            setSelectedBuildingId(null);
            setBuildingDetails({});
            updateField('Building.RegionNumber', regionNumber);
            fetchAndSetBuildingsForRegion(regionNumber);
        }
    }, [selectedRegionNumber, updateField]);

    const handleBuildingSelection = useCallback((selectedItem) => {
        const buildingId = selectedItem.BuildingId;
        if (selectedItem && buildingId !== selectedBuildingId) {
            setSelectedBuildingId(buildingId);
            updateField('BuildingId', buildingId);
            updateField('Building.SequenceNumber', selectedItem.SequenceNumber);
            setBuildingDetails({
                ...selectedItem.Location,
                Name: selectedItem.Name
            });
            updateField('Building.Location', selectedItem.Location);
            updateField('Building.Name', selectedItem.Name);
        }
    }, [selectedBuildingId, updateField]);

    const calculateTotalNonHolidayDays = useCallback((startDate, endDate, scheduleArray, holidaysArray) => {
        let current = dayjs(startDate);
        const end = dayjs(endDate);
        let nonHolidays = 0;

        while (current.isBefore(end) || current.isSame(end)) {
            const dayOfWeek = current.format('dddd');
            if (scheduleArray.includes(dayOfWeek) && !holidaysArray.includes(current.format('YYYY-MM-DD'))) {
                nonHolidays++;
            }
            current = current.add(1, 'day');
        }
        return nonHolidays;
    }, [postFormData.StartDate, postFormData.EndDate, postFormData.WeeklySchedule]);

    const calculateTotalHolidayDays = useCallback((startDate, endDate, holidaysSelected) => {
        if (holidaysSelected.includes("No Holidays Worked")) {
            return 0;
        }
        const holidays = {
            "New Year’s Day": dayjs(`${dayjs(startDate).year()}-01-01`),
            "Martin Luther King’s Day": dayjs(`${dayjs(startDate).year()}-01-15`),
            "Washington’s Birthday": dayjs(`${dayjs(startDate).year()}-02-19`),
            "Memorial Day": dayjs(`${dayjs(startDate).year()}-05-30`),
            "Juneteenth": dayjs(`${dayjs(startDate).year()}-06-19`),
            "Independence Day": dayjs(`${dayjs(startDate).year()}-07-04`),
            "Labor Day": dayjs(`${dayjs(startDate).year()}-09-04`),
            "Columbus Day": dayjs(`${dayjs(startDate).year()}-10-09`),
            "Veterans’ Day": dayjs(`${dayjs(startDate).year()}-11-11`),
            "Thanksgiving Day": dayjs(`${dayjs(startDate).year()}-11-24`),
            "Christmas Day": dayjs(`${dayjs(startDate).year()}-12-25`)
        };

        const start = dayjs(startDate);
        const end = dayjs(endDate);
        let totalHolidays = 0;

        holidaysSelected.forEach(holiday => {
            const holidayDate = holidays[holiday];
            if (holidayDate && holidayDate.isBetween(start, end, null, '[]')) {
                totalHolidays++;
            }
        });

        return totalHolidays;
    }, [postFormData.StartDate, postFormData.EndDate, postFormData.HolidaySchedule]);

    const calculateTotalHolidayHours = useCallback((holidayStartTime, holidayEndTime, totalHolidayDays) => {
        const startTime = dayjs(holidayStartTime);
        const endTime = dayjs(holidayEndTime);
        const diff = endTime.diff(startTime, 'hour', true);
        return (diff * totalHolidayDays);
    }, [postFormData.HolidayStartTime, postFormData.HolidayEndTime, postFormData.HolidaySchedule]);


    const onSaveClick = async ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) return;

        const isFormChanged = (original, current) => {
            return JSON.stringify(original) === JSON.stringify({
                ...current,
                // Optionally exclude certain fields from comparison if needed
            });
        }

        if (isFormChanged(defaultPostFormData, postFormData)) {
            navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
                state: {
                    postExhibit: postExhibitFormData,
                    isEditing: true,
                    isNewPostExhibit: isNewPostExhibit
                }
            });

            return;
        }

        let updatedPosts = [];
        if (postExhibitFormData?.Posts) updatedPosts = [...postExhibitFormData.Posts];

        if (postFormData.PostId && !isNewPost) {
            const postIndex = updatedPosts.findIndex(
                post => post.PostId === postFormData.PostId
            );
            if (postIndex !== -1) {
                updatedPosts[postIndex] = {
                    ...updatedPosts[postIndex],
                    ...postFormData
                };
            }
        } else {
            updatedPosts.push(postFormData);
        }

        const updatedPostExhibit = {
            ...postExhibitFormData,
            Posts: updatedPosts
        };

        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibit: updatedPostExhibit,
                isEditing: true,
                isNewPostExhibit: isNewPostExhibit
            },
            // replace: true
        });
    }

    const onCancelClick = () => {
        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibit: postExhibitFormData,
                isEditing: true,
                isNewPostExhibit: isNewPostExhibit
            }
        });
    }

    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split('T')[0];
    }

    const formatTime = (time) => {
        if (!time) return null;
        const formattedTime = new Date(time);

        // Convert to hours as a decimal (double precision)
        const hours = formattedTime.getHours();
        const minutes = formattedTime.getMinutes();
        const decimalTime = hours + (minutes / 60);

        return parseFloat(decimalTime.toFixed(4));
    }

    const formatTimeForUI = (decimalTime) => {
        if (decimalTime === null || decimalTime === undefined) return null;
        
        const hours = Math.floor(decimalTime);
        const minutes = Math.round((decimalTime % 1) * 60);
        
        return new Date(1970, 0, 1, hours, minutes);
      };

    return (
        <div>
            <h1>Post Details</h1>
            <ValidationGroup>
                <ToolbarForm
                    toggleEditing={() => setEditing(!editing)}
                    onSaveClick={onSaveClick}
                    editing={editing}
                    onCancelClick={onCancelClick}
                />
                <Form>
                    <GroupItem caption="General Post Details" colCount={4}>

                        <FormItem>
                            <SelectBox
                                label={"Region"}
                                dataSource={dropdownData.regions}
                                displayExpr={"RegionNumber"}
                                valueExpr={"RegionNumber"}
                                value={selectedRegionNumber}
                                readOnly={!editing}
                                onSelectionChanged={(e) => handleRegionSelection(e.selectedItem)}
                                searchEnabled={true}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                label={"BuildingNumber"}
                                dataSource={buildingsData}
                                displayExpr={"SequenceNumber"}
                                valueExpr={"BuildingId"}
                                value={selectedBuildingId}
                                readOnly={!editing}
                                onSelectionChanged={(e) => handleBuildingSelection(e.selectedItem)}
                                searchEnabled={true}
                                disabled={!selectedRegionNumber}
                            />
                        </FormItem>
                        <FormItem >
                            <FormTextBox
                                label={'Building Name'}
                                value={buildingDetails.Name || ""}
                                isEditing={false}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={'Address'}
                                value={buildingDetails.Address1 || ""}
                                isEditing={false}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={"City Name"}
                                value={buildingDetails.CityName || ""}
                                readOnly={true}
                                isEditing={false}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={"State"}
                                value={buildingDetails.State || ""}
                                isEditing={false}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={"ZIP"}
                                value={buildingDetails.ZipCode || ""}
                                isEditing={false}
                            />
                        </FormItem>
                        <FormItem >
                            <FormTextBox
                                label={"Post Name"}
                                value={postFormData?.PostLocation?.Name}
                                onValueChange={e => updateField('PostLocation.Name', e)}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                label={"Post Status"}
                                dataSource={dropdownData.postStatuses}
                                displayExpr={"Name"}
                                valueExpr={"PostStatusId"}
                                value={postFormData?.PostStatusId}
                                readOnly={!editing}
                                onSelectionChanged={({ selectedItem }) => { updateField('PostStatusId', selectedItem.PostStatusId) }}
                                searchEnabled={true}
                            ></SelectBox>
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                inputAttribute={{ "aria-label": "Fee Type" }}
                                dataSource={dropdownData.feeTypes}
                                enableSearch={true}
                                label={"Fee Type"}
                                onSelectionChanged={({ selectedItem }) => updateField('FeeTypeId', selectedItem.FeeTypeId)}
                                value={postFormData?.FeeTypeId}
                                displayExpr="FeeTypeName"
                                valueExpr="FeeTypeId"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                inputAttribute={{ "aria-label": "Primary Post Type" }}
                                dataSource={dropdownData.postTypes}
                                enableSearch={true}
                                label={"Primary Post Type"}
                                onSelectionChanged={({ selectedItem }) => updateField('PrimaryPostTypeId', selectedItem.PostTypeId)}
                                value={postFormData?.PrimaryPostTypeId}
                                displayExpr="Name"
                                valueExpr="PostTypeId"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                inputAttribute={{ "aria-label": "Secondary Post Type" }}
                                dataSource={dropdownData.postTypes}
                                enableSearch={true}
                                label={"Secondary Post Type"}
                                onSelectionChanged={({ selectedItem }) => updateField('SecondaryPostTypeId', selectedItem.PostTypeId)}
                                value={postFormData?.SecondaryPostTypeId}
                                displayExpr="Name"
                                valueExpr="PostTypeId"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                inputAttribute={{ "aria-label": "Screener" }}
                                dataSource={dropdownData.screeners}
                                enableSearch={true}
                                label={"Screeners"}
                                onSelectionChanged={({ selectedItem }) => updateField('ScreenerId', selectedItem.ScreenerId)}
                                value={postFormData?.ScreenerId}
                                displayExpr="Name"
                                valueExpr="ScreenerId"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <SelectBox
                                inputAttribute={{ "aria-label": "Fitness/Security level" }}
                                dataSource={dropdownData.securityLevels}
                                searchEnabled={true}
                                label={"Fitness/Security Level"}
                                onSelectionChanged={({ selectedItem }) => updateField('SecurityLevelId', selectedItem.SecurityLevelId)}
                                value={postFormData?.SecurityLevelId}
                                displayExpr="Name"
                                valueExpr="SecurityLevelId"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem >
                            <BaseCheckBox
                                elementAttr={{ "aria-label": "Relief On Break" }}
                                onCheckedValueChanged={({ value }) => updateField('ReliefBreak', value)}
                                text="Relief On Break"
                                value={postFormData?.ReliefBreak}
                                name="ReliefBreak"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem  >
                            <SelectBox
                                inputAttribute={{ "aria-label": "Total Number of PTS Tablets Required" }}
                                dataSource={[...Array(11).keys()]} //number from 0 to 10
                                searchEnabled={true}
                                label={"Total Number of PTS Tablets Required"}
                                onSelectionChanged={({ selectedItem }) => updateField('Utilization', selectedItem)}
                                value={postFormData?.Utilization}
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem >
                            {/* <FormTextBox
                                label={"Special Requirements"}
                                // maxLength={500}
                                // onValueChanged={e => updateField('SpecialRequirements', e)}
                                value={postFormData?.SpecialRequirements}
                                readOnly={!editing}
                            />                             */}
                            <TextBox
                                label={"Special Requirements"}
                                // maxLength={500}
                                // onValueChanged={e => updateField('SpecialRequirements', e)}
                                value={postFormData?.SpecialRequirements}
                                readOnly={!editing}
                            />                            
                        </FormItem>
                        <FormItem  >
                            <NumberBox
                                inputAttribute={{ "aria-label": "PSO Hourly Rate" }}
                                format={"$ #,##0"}
                                label="PSO Post Hourly Rate"
                                onValueChanged={e => updateField('HourlyRate', e.value)}
                                value={postFormData?.HourlyRate}
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem >
                            <DateBox
                                type="datetime"
                                inputAttr={{ "aria-label": "Start Date" }}
                                label="Start Date"
                                min={minDate}
                                value={postFormData?.StartDate}
                                showDropDownButton={true}
                                onValueChanged={e => {
                                    const formattedDate = formatDate(e.value)
                                    updateField('StartDate', formattedDate)
                                }}
                                validationMessage="Please enter a valid start date."
                                displayFormat={"MM/dd/yyyy"}
                                placeholder='MM/dd/yyyy'
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem >
                            <DateBox
                                type="datetime"
                                inputAttr={{ "aria-label": "End Date" }}
                                label="End Date"
                                min={minDate}
                                value={postFormData?.EndDate}
                                showDropDownButton={true}
                                onValueChanged={e => {
                                    const formattedDate = formatDate(e.value)
                                    updateField('EndDate', formattedDate)
                                }}
                                displayFormat={"MM/dd/yyyy"}
                                placeholder={"MM/dd/yyyy"}
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem >
                            <DateBox
                                type="time"
                                inputAttr={{ "aria-label": "Start Time" }}
                                label="Start Time"
                                value={formatTimeForUI(postFormData?.StartTime)}
                                onValueChanged={e => {
                                    const formattedTime = formatTime(e.value)
                                    updateField('StartTime', formattedTime)
                                }}
                                validationMessage="Please enter a valid start time."
                                placeholder="~9:00 AM"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <DateBox
                                type="time"
                                inputAttr={{ "aria-label": "End Time" }}
                                label="End Time"
                                value={formatTimeForUI(postFormData?.EndTime)}
                                onValueChanged={e => {
                                    const formattedTime = formatTime(e.value)
                                    updateField('EndTime', formattedTime)
                                }}
                                validationMessage="Please enter a valid end time."
                                placeholder="~5:00 PM"
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={"Total Hours Per Day"}
                                value={calculatedFields?.totalHoursPerDay}
                                readonly={true}
                            />
                        </FormItem>
                        <FormItem>
                            <TagBox
                                inputAttr={{ "aria-label": "Schedule" }}
                                label="Schedule"
                                items={getWeekDaysOptions()}
                                displayExpr="text"
                                valueExpr="text"
                                showSelectionControls={true}
                                value={postFormData?.WeeklySchedule.split(',')}
                                defaultValue={postFormData.WeeklySchedule ? postFormData.WeeklySchedule.split(',') : []}
                                onValueChanged={(e) => {
                                    const filteredSelectedDays = e.value.filter(day => day !== '');
                                    const selectedDays = filteredSelectedDays.join(',');
                                    if (selectedDays !== postFormData.WeeklySchedule) {
                                        updateField('WeeklySchedule', selectedDays);
                                    }
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <FormTextBox
                                label={"Total Non-Holidays"}
                                value={calculatedFields?.totalNonHolidays}
                                isValid={true}
                                readonly={true}
                            />
                        </FormItem>
                        <FormItem>
                            <TagBox
                                inputAttr={{ "aria-label": "Holiday Work Schedule" }}
                                label="Holiday Work Schedule"
                                items={getHolidayOptions()}
                                displayExpr="text"
                                valueExpr="text"
                                showSelectionControls={true}
                                value={postFormData.HolidaySchedule.split(',')}
                                defaultValue={postFormData.HolidaySchedule ? postFormData.HolidaySchedule.split(',') : []}
                                onValueChanged={(e) => {
                                    const filteredSelectedDays = e.value.filter(day => day !== '');
                                    const selectedDays = filteredSelectedDays.join(',');
                                    if (selectedDays !== postFormData.HolidaySchedule) {
                                        updateField('HolidaySchedule', selectedDays);
                                    }
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <NumberBox
                                inputAttribute={{ "aria-label": "Total Holiday Days" }}
                                label={"Total Holiday Days"}
                                value={calculatedFields.totalHolidayDays}
                                readOnly={true}
                            />
                        </FormItem>
                        <FormItem>
                            <NumberBox
                                inputAttribute={{ "aria-label": "Total Calendar Days Worked" }}
                                label="Total Calendar Days Worked"
                                value={calculatedFields.totalCalendarDaysWorked}
                                readOnly={true}
                            />
                        </FormItem>
                        <FormItem>
                            <NumberBox
                                inputAttribute={{ "aria-label": "Temporary Additional Service Hours" }}
                                label="Temporary Additional Service Hours"
                                value={postFormData?.AdditionalServiceHours}
                                onValueChanged={(e) => updateField('AdditionalServiceHours', e.value)}
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <NumberBox
                                inputAttribute={{ "aria-label": "Total Hours" }}
                                label="Total Hours"
                                value={isNaN(calculatedFields.totalHours) ? null : calculatedFields.totalHours}
                                readOnly={true}
                            />
                        </FormItem>
                        <FormItem>
                            <DateBox
                                type='time'
                                inputAttr={{ "aria-label": "Holiday Work Start Time" }}
                                label="Holiday Work Start Time"
                                value={formatTimeForUI(postFormData?.HolidayStartTime)}
                                onValueChanged={e => {
                                    const formattedTime = formatTime(e.value)
                                    updateField('HolidayStartTime', formattedTime)
                                }}
                                placeholder='~8:00 AM'
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <DateBox
                                type='time'
                                inputAttribute={{ "aria-label": "Holiday Work End Time" }}
                                label="Holiday Work End Time"
                                value={formatTimeForUI(postFormData?.HolidayEndTime)}
                                onValueChanged={(e) => {
                                    const formattedTime = formatTime(e.value)
                                    updateField('HolidayEndTime', formattedTime)
                                }}
                                placeholder='~5:00 PM'
                                readOnly={!editing}
                            />
                        </FormItem>
                        <FormItem>
                            <NumberBox
                                label="Total Holiday Hours"
                                value={isNaN(calculatedFields.totalHolidayHours) ? null : calculatedFields.totalHolidayHours}
                                readOnly={true}
                            />
                        </FormItem>
                    </GroupItem>
                </Form>
            </ValidationGroup>
            {/* )} */}
        </div>
    );
}


// ================== HELPER FUNCTIONS ============================ //

function getWeekDaysOptions() {
    return [
        { id: 1, text: 'Monday' },
        { id: 2, text: 'Tuesday' },
        { id: 3, text: 'Wednesday' },
        { id: 4, text: 'Thursday' },
        { id: 5, text: 'Friday' },
        { id: 6, text: 'Saturday' },
        { id: 7, text: 'Sunday' }
    ]
}

function getHolidayOptions() {
    return [
        { id: 1, text: "New Year’s Day" },
        { id: 2, text: "Martin Luther King’s Day" },
        { id: 3, text: "Washington’s Birthday" },
        { id: 4, text: "Memorial Day" },
        { id: 5, text: "Juneteenth" },
        { id: 6, text: "Independence Day" },
        { id: 7, text: "Labor Day" },
        { id: 8, text: "Columbus Day" },
        { id: 9, text: "Veterans’ Day" },
        { id: 10, text: "Thanksgiving Day" },
        { id: 11, text: "Christmas Day" },
        { id: 12, text: "Other" },
        { id: 13, text: "No Holidays Worked" }
    ]
}