import {
    useState,
    useCallback,
    useEffect
} from 'react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import DataGridSpecificCostEstimate from './DataGridSpecificCostEstimate';
import GetFetch from '../../hooks/GetFetch';
import './SpecificCostEstimate.scss'
import { KeyboardNavigation } from 'devextreme-react/data-grid';
import FiscalYearSelectDropdown from '../FiscalYearSelectDropdown'
import IsNullOrEmpty from '../../components/IsNullOrEmpty';
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { BaseToggle } from "../../components/BaseToggle/BaseToggle";

export default function SpecificCostEstimate({feeTypeFilter, pageTitle}) {
    const [selectedFiscalYear, setSelectedFiscalYear] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [specificPostsTabData, setSpecificPostsTabData] = useState({
        0: null, // Current Year == Fiscal Year
        1: null, // Budget Year == Fiscal Year +1 
        2: null, // Budget Year+1 == Fiscal Year +2
        3: null, // Budget Year+2 == Fiscal Year +3
        4: null  // Budget Year+3 == Fiscal Year +4
    });
    const [isTabDataReset, setIsDataReset] = useState(false);
    const [groupingToggle, setGroupingToggle] = useState(true);
    const [enableToggle, setEnableToggle] = useState(true); 

    const fetchPostsByFiscalYear = useCallback(async (tabIndex) => {
        if (!selectedFiscalYear || specificPostsTabData[tabIndex]) return;
        setLoading(true);
        const fiscalYear = selectedFiscalYear + tabIndex;
        const posts = await GetFetch(`/v1/Post/GetPostsByFiscalYear/${fiscalYear}`);
        const { Success, Message, Errors } = posts;

        if (Success) {
            setEnableToggle(false);
            const specificPosts = Message.filter(post => post.FeeTypeId == feeTypeFilter);
            setSpecificPostsTabData(prevData => ({
                ...prevData,
                [tabIndex]: specificPosts
            }));
        }
        else {
            setError(true);
            setEnableToggle(true);
            if (Errors[0].Message === "No records found at the moment") {
                console.log(Errors[0].Message);
            }
        }
        setLoading(false);
    }, [selectedFiscalYear, specificPostsTabData]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSelectedTabIndex(0);
            setSpecificPostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
            setIsDataReset(true);
        }
    }

    const handleSubmit = async () => {
        setSelectedTabIndex(0);
        setSpecificPostsTabData({ 0: null, 1: null, 2: null, 3: null, 4: null });
        setIsDataReset(true);
    }

    useEffect(()=> {
        if (isTabDataReset) {
            fetchPostsByFiscalYear(0);
            setIsDataReset(false);
        }
    }, [isTabDataReset]);

    const onTabSelectionChanged = useCallback((e) => {
        const newTabIndex = e.component.option('selectedIndex');
        setSelectedTabIndex(newTabIndex);
        fetchPostsByFiscalYear(newTabIndex);
    }, [fetchPostsByFiscalYear]);

    const hint = groupingToggle ?
        "Click to enable grouping" : "Click to disable grouping";

    const switchElemAttr = {
        id: 'switch-element-attribute'
    }

    return (
        <div>
            <h1 style={{ color: "#06152B" }}>{pageTitle}</h1>
            <em style={{ color: "#06152B" }}>
                Please select a current year
            </em>
            <div className="inline__div--wrapper"
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: '100%'
                }}
            >
                <FiscalYearSelectDropdown
                    inputAttribute={{ "aria-label": "Current Year *" }}
                    enableSearch={true}
                    label={"Current Year"}
                    onEnterKeyDown={handleKeyDown}
                    value={selectedFiscalYear}
                    optionUpdatedByUser={({ selectedItem }) => setSelectedFiscalYear(selectedItem)}
                    isVisible={true}
                />
                <BaseButton 
                    ariaDisabled={IsNullOrEmpty(selectedFiscalYear)}
                    disabled={IsNullOrEmpty(selectedFiscalYear)}
                    label={"Search"}
                    onClick={handleSubmit}
                    variant={"outlined"}
                />
                 <BaseToggle
                    disabled={enableToggle}
                    elementAttribute={switchElemAttr}
                    hint={hint}
                    name={"toggle grouping"}
                    valueUpdatedByUser={({value}) => setGroupingToggle(value)}
                    rightToLeftEnabled={true}
                    switchedOnText={"Enable Grouping"}
                    switchedOffText={"Disable Grouping"}
                    width={120}
                    value={groupingToggle} 
                />

            </div>
            <div className='inline_div--tabPanel-wrapper'>
                <TabPanel id="tabPanel" onSelectionChanged={onTabSelectionChanged} selectedIndex={selectedTabIndex}>
                    <KeyboardNavigation enabled={true} />
                    <TabPanelItem title={`Current Year (${selectedFiscalYear})`} id="cy-cost-est" aria-label="cy-cost-est">
                        <DataGridSpecificCostEstimate posts={specificPostsTabData[0]} fiscalYear={selectedFiscalYear} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year (${selectedFiscalYear + 1})`} id="budget-year-cost-est" aria-label="budget-year-cost-est">
                        <DataGridSpecificCostEstimate posts={specificPostsTabData[1]} fiscalYear={selectedFiscalYear + 1} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+1 (${selectedFiscalYear + 2})`} id="budget-year-cost-est+1" aria-label="budget-year-cost-est+1">
                        <DataGridSpecificCostEstimate posts={specificPostsTabData[2]} fiscalYear={selectedFiscalYear + 2} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+3 (${selectedFiscalYear + 3})`} id="budget-year-cost-est+2" aria-label="budget-year-cost-est+2">
                        <DataGridSpecificCostEstimate posts={specificPostsTabData[3]} fiscalYear={selectedFiscalYear + 3} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                    <TabPanelItem title={`Budget Year+4 (${selectedFiscalYear + 4})`} id="budget-year-cost-est+4" aria-label="budget-year-cost-est+4">
                        <DataGridSpecificCostEstimate posts={specificPostsTabData[4]} fiscalYear={selectedFiscalYear + 4} groupingEnabled={groupingToggle} />
                    </TabPanelItem>
                </TabPanel>
            </div>
        </div>
    );
}