import { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { FormTextBox } from '../FormTextBox/FormTextBox';
import { FormDateBox } from '../FormDateBox/FormDateBox';
import DataGrid, { Column, GroupItem as DataGridGroup, Form as DataGridForm, Lookup, Toolbar, Item, Editing, Popup, ValidationRule, RequiredRule, StringLengthRule, RangeRule } from 'devextreme-react/data-grid';
import { ContractPersonnel } from './ContractPersonnel';
import { TaskOrderForm } from './TaskOrderForm';
import Button from 'devextreme-react/button';
import { Contract, TaskOrder } from '../../types/contract';
import GetFetch from '../../hooks/GetFetch';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import { EmailRule } from 'devextreme-react/validator';
const URL_productCodes = '/v1/ProductCode/GetProductCodes';
const URL_productDescriptions = '/v1/ProductCode/GetProductCodeById/1';
const URL_taskOrderTypes = '/v1/TaskOrderType';
const URL_regions = '/v1/Region';
import { Endpoints } from '../../Enums/endpoints';

import './ContractForm.scss';

const {
  GET_PRODUCT_CODE_BY_ID,
  GET_PRODUCT_CODES,
  GET_TASKORDER_TYPE
} = Endpoints;

let pdList = [];

/** @typedef {import('../../types/contract').Contract} Contract */
/** @typedef {import('../../types/contract').TaskOrder} TaskOrder */

/**
 * @param {Contract} data
 * @param {boolean} editing
 * @param {function(field, value): void} updateField
 */

export const ContractFormDetails = ({ data, editing, updateField, updateObject }) => {

  const [regions, setRegions] = useState([]);
  const [taskOrderTypes, setTaskOrderTypes] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const [productDescriptions, setProductDescriptions] = useState([]);
  const [editLabel, setEditLabel] = useState();
  const [formDataDefaults, setFormDataDefaults] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const gridRef = useRef(null);

  //setFormDataDefaults(data);

  useEffect(() => {
    fetchAndSet(URL_regions, setRegions);
    fetchAndSet(URL_taskOrderTypes, setTaskOrderTypes);
    fetchAndSet(URL_productCodes, setProductCodes);
    fetchAndSet(URL_productDescriptions, setProductDescriptions)
  }, []);

  const fetchAndSet = async (URL, setter) => {
    const { Errors, Message, Success } = await GetFetch(URL);
    if (Success === true) {
      // URL == `${GET_PRODUCT_CODE_BY_ID}1` ?
      URL == '/v1/ProductCode/GetProductCodeById/1' ?
        setter(Message.ProductDescriptions) :
        setter(Message);
      console.log(Message);
    }
    else {
      console.error(Errors[0].Message);
    }
  }

  const renderTaskOrderForm = (e) => {
    const editItem = "Edit Task Order: " + e.row.data.Number;
    //return <a href='#' target='_self' aria-label={editItem} onClick={e => onEditTaskOrder(e)}>Edit</a>;
    return <Button onClick={onEditTaskOrder(e.row.data)}>Edit</Button>
  }

  const onEditTaskOrder = useCallback((e) => {
    // setFormDataDefaults(e);
    // e.DataGrid.popupVisible = true;
    setPopupVisible(true);
  }, []);

  const editRow = useCallback((e) => {
    const intRow = parseInt(e.element.id);
    setSelectedRowIndex(intRow);
    setPopupVisible(true);
    gridRef.current.instance.editRow(intRow);
    // gridRef.current.instance().deselectAll();
  }, [gridRef, selectedRowIndex]);

  const buttonRender = useCallback((e) => {
    const taskOrderId = e.data.TaskOrderId;
    setSelectedRowIndex(e.row.rowIndex);
    // return <Link to='#' onClick={e => editRow(e)}>{'Edit Task Order: ' + taskOrderId}</Link>
    // return <a href='#' class="dx-link dx-link-edit" onClick={e => editRow(e)}>{'Edit Task Order: ' + taskOrderId}</a>;
    return <Button id={e.rowIndex} onClick={e => editRow(e)}>{'Edit Task Order: ' + taskOrderId}</Button>
  }, []);

  const onAddTaskOrder = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
  }, []);

  const onDataChanged = (field, newValue) => {
    //formDataDefaults[field] = newValue;
    //setFormDataDefaults({ ...formDataDefaults });
    data[field] = newValue
    updateField('Number', e)
  };

  const onEditVisible = useCallback((e) => {
    const editItem = { "aria-label": "Edit Task Order: " + e.row.data.Number };
    setEditLabel(editItem);
  });

  const onSaveClick = useCallback(() => {
    notify({
      message: `New contact "${newContactData.firstName} ${newContactData.lastName}" saved`,
      position: { at: 'bottom center', my: 'bottom center' }
    },
      'success'
    );
    setFormDataDefaults({ ...formDataDefaults });
    setPopupVisible(false);
  }, []);

  const onCloneIconClick = useCallback((e) => {
    const clonedItem = { ...e.row.data, ID: getMaxID() };
  });

  return (
    <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'>
      <GroupItem colCount={4} caption='General Information' >
        <ColCountByScreen xs={4} />
        <FormItem>
          <FormTextBox
            label='Contract Number'
            value={data.Number}
            isEditing={editing}
            onValueChange={e => onDataChanged('Number', e)}
          />
        </FormItem>
        <FormItem cssClass='accent'>
          <FormDateBox
            label='Award Date'
            value={data.AwardDate}
            isEditing={!editing}
            onValueChange={e => onDataChanged('AwardDate', e)}
          />
        </FormItem>
        <FormItem>
          <FormDateBox
            label='Start Date'
            value={data.StartDate}
            isEditing={!editing}
            onValueChange={e => onDataChanged('StartDate', e)}
          />
        </FormItem>
        <FormItem cssClass='accent'>
          <FormDateBox
            label='End Date'
            value={data.EndDate}
            isEditing={!editing}
            onValueChange={e => onDataChanged('EndDate', e)}
          />
        </FormItem>
      </GroupItem>

      <GroupItem caption='Contract Personnel'>
        <ContractPersonnel data={data.ContractPersonnel} editing={editing} updateField={updateObject} />
      </GroupItem>

      <GroupItem caption='Task Orders'>
        <DataGrid
          id='myDataGrid'
          dataSource={data.TaskOrders}
          showBorders={true}
          columnAutoWidth={true}
          ref={gridRef}
          key="TaskOrderId">
          <Toolbar visible={editing} >
            <Item name="addRowButton" showText="always" caption="Add Task Order" />
            {/*             <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text='Add Task Order'
                type='default'
                stylingMode='contained'
                disabled={!editing}
                onClick={onAddTaskOrder}
              />
            </Item> */}
          </Toolbar>
          <Editing mode="popup" allowUpdating={true} allowAdding={true}  >
            <Popup
              showTitle={true}
              title="Editing A Task Order"
              visible={popupVisible}
              Editing={popupVisible}
            />
            <DataGridForm
              labelLocation="top">
              <Item caption='General Information' itemType='group' colCount={2}>
                <Item itemType='group'>
                  <Item dataField='TaskOrderTypeId' caption-='Task Order Type' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item dataField='Number' caption='Task Order Number' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item dataField='StartDate' caption='Start Date' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item dataField='EndDate' caption='End Date' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                </Item>
                <Item itemType='group'>
                  <Item caption='Award Date' dataField='AwardDate' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item caption='Vendor Name' dataField='VendorName' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item caption='Region' dataField='RegionId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item caption='Product Description' dataField='ProductDescriptionId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                  <Item caption='Product Code' dataField='ProductCodeId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                </Item>
              </Item>
              <Item caption='Financial Codes' itemType='group'>
                <Item
                  dataField='FinancialCodes.AccountCode'
                  caption='Account Code'
                  labelMode='static' elementAttr={{ class: 'form-editor' }}
                  onValueChange={updateField('FinancialCodes.AccountCode')}
                />
                <Item
                  caption='Testing'
                  dataField='FinancialCodes.AccSequenceNumber'
                  labelMode='static' elementAttr={{ class: 'form-editor' }}
                  onValueChange={updateField('FinancialCodes.AccSequenceNumber')}
                />
                <Item
                  dataField='FinancialCodes.AccObligatedAmount'
                  caption='AccObligatedAmount'
                  labelMode='static' elementAttr={{ class: 'form-editor' }}
                  onValueChange={updateField('FinancialCodes.AccObligatedAmount')}
                />
                <Item
                  dataField='FinancialCodes.TreasuryAccSymbol'
                  caption='TreasuryAccSymbol'
                  labelMode='static' elementAttr={{ class: 'form-editor' }}
                  onValueChange={updateField('FinancialCodes.TreasuryAccSymbol')}
                />
                <Item
                  dataField='FinancialCodes.CageCode'
                  caption='AccObligatedAmoCageCodeunt'
                  labelMode='static' elementAttr={{ class: 'form-editor' }}
                  onValueChange={updateField('FinancialCodes.CageCode')}
                />
              </Item>
            </DataGridForm>
          </Editing>
          {/*           <Editing
            mode="popup"
            allowUpdating={true} 
            popup={{
              hideOnOutsideClick: true,
              title: "Edit A Task Orders",
              container: ".dx-viewport",
              showTitle: true,
              toolbarItems: [
                {
                  toolbar: 'bottom',
                  widget: 'dxButton',
                  location: 'after',
                  options: {
                    text: 'Update Task Order',
                    // onClick: (e) => { console.log("Clicked save button: ", e) }
                  }
                },
                {
                  toolbar: 'bottom',
                  widget: 'dxButton',
                  location: 'after',
                  options: {
                    text: 'Cancel',
                    onClick: (e) => {
                      // console.log("Cancel ", dataGridRef),
                      dataGridRef.current.instance.cancelEditData()
                    }
                  }
                }
              ]
            }}
          >
          </Editing> */}
          {/*           <Column 
            type="buttons" 
            width={50} 
            visible={editing} 
            onVisibleChange={e => {e.elementAttr = {"aria-label": "Task Order: " + e.data.Number}}}
          >
            <Button 
              name="edit"
              elementAttr={{"aria-label":"Task Order"}} 
            />
          </Column> */}
          {/* <Column dataField="Number" name="Edit" caption="" width={50} cellRender={(e) => renderTaskOrderForm(e)} /> */}
          <Column type='buttons' visible={editing} cellRender={buttonRender}>
            <Button
              name='edit'
              elementAttr={{ "aria-label": "Billing Date" }}
            />
          </Column>
          <Column dataField="Number" caption="Task Order Number">
            <RequiredRule message="Task Order Number is required" />
            <StringLengthRule min={2} message="Task Order Number length must be a minimum of 2 characters" />
          </Column>
          <Column dataField="TaskOrderTypeId" caption="Task Order Type">
            <Lookup
              dataSource={taskOrderTypes}
              displayExpr="Name"
              valueExpr="TaskOrderTypeId"
            />
            <RequiredRule message="Task Order Type is required" />
          </Column>
          <Column dataField="StartDate" caption="Start Date">
            <RequiredRule message="Start Date is required" />
          </Column>
          <Column dataField="EndDate" caption="End Date">
            <RequiredRule message="End Date is required" />
          </Column>
          <Column dataField="AwardDate" caption="Award Date">
            <RequiredRule message="Award Date is required" />
          </Column>
          <Column dataField="RegionId" caption="Region">
            <Lookup
              dataSource={regions}
              displayExpr="RegionNumber"
              valueExpr="RegionNumber"
            />
            <RequiredRule message="Description is required" />
          </Column>
          <Column dataField="VendorName" caption="Vendor Name">
            <RequiredRule message="Vendor Name is required" />
            <StringLengthRule min={2} message="Task Order Number length must be a minimum of 2 characters" />
          </Column>
          <Column dataField="ProductDescriptionId" caption="Product Description">
            <Lookup
              dataSource={productDescriptions}
              displayExpr="Description"
              valueExpr="ProductDescriptionId"
            />
            <RequiredRule message="Description is required" />
          </Column>
          <Column dataField="ProductCodeId" caption="Product Code">
            <Lookup
              dataSource={productCodes}
              displayExpr="Code"
              valueExpr="ProductCodeId"
            />
            <RequiredRule message="Product Code is required" />
          </Column>
          <Column dataField='FinancialCodes.AccountCode' visible={false}></Column>
        </DataGrid>
      </GroupItem>
      {/*       <FormPopup title='Add or Edit Task Order' visible={popupVisible} setVisible={changePopupVisibility} onSave={onSaveClick}>
        <TaskOrderForm initData={formDataDefaults} onDataChanged={onDataChanged} />
      </FormPopup> */}
    </Form>
  );
};